import { render, staticRenderFns } from "./InvoiceCorporationDetails.vue?vue&type=template&id=b8fe8680&"
import script from "./InvoiceCorporationDetails.vue?vue&type=script&lang=ts&"
export * from "./InvoiceCorporationDetails.vue?vue&type=script&lang=ts&"


/* normalize component */
import normalizer from "!../../../../../.yarn/__virtual__/vue-loader-virtual-93e13427ca/0/cache/vue-loader-npm-15.9.8-438f4d0ff0-ca4c99b261.zip/node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  null,
  null
  
)

export default component.exports